import React, { useState } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import get from 'lodash.get'
import { RichText } from 'prismic-reactjs'
import Carousel from './Carousel/Carousel'

import button from '../images/button.png'

import arrow from '../images/arrow-swipe.svg'
import cursorWhite from '../images/cursor-white.svg'

const Bio = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  padding: 50px 40px;
  color: #efe0d7;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.25px;
  background-color: #0859ff;
  // overflow: hidden;
  overflow: auto;
  transition: 0.25s ease-out;
  margin-right: 20px;
  @media (min-width: 680px) {
    padding: 20px 12px;
  }
  @media (min-width: 800px) {
    padding: 30px 30px;
  }
  @media (min-width: 950px) {
    padding: 50px 40px;
  }
  @media (min-width: 1200px) {
    margin-right: 0px;
    opacity: 0;
  }
  p {
    :not(:last-child) {
      margin-bottom: 34px;
    }
  }
`

const Wrapper = styled.div`
  position: relative;
  :hover {
    ${Bio} {
      opacity: 1;
      // cursor: url(${cursorWhite}) 7 7, auto;
    }
  }
  .embla__slide__inner {
    height: 100%;
  }
  .embla__outer {
    padding-bottom: 0px;
  }
`

const TestimonialImgContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  padding-top: 38px;
  margin-bottom: 30px;
  border-radius: 200px;
  @media (min-width: 680px) {
    margin-top: 70px;
    margin-bottom: 40px;
    ${p => p.smallOnTablet && 'margin-bottom: 25px;'}
  }
  @media (min-width: 1200px) {
    margin-top: 154px;
    padding-top: 20px;
    margin-bottom: 40px;
  }
`

const TestimonialImg = styled(Img)`
  width: 150px;
  height: 150px;
  border-radius: 200px;
  img {
    border-radius: 200px;
  }
  @media (min-width: 680px) {
    width: 213px;
    height: 213px;
    ${p => p.smallOnTablet && 'width: 141px; height: 141px;'}
  }
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 11px;
  @media (min-width: 680px) {
    margin-bottom: 53px;
  }
`

const Button = styled.img`
  width: 62px;
  @media (min-width: 680px) {
    width: 83px;
    ${p => p.smallOnTablet && 'width: 55px;'}
  }
`

const Description = styled.div`
  padding-bottom: 10px;
  border-bottom: 3px solid #efe0d7;
  ${p => p.carousel && 'margin-left: 30px; margin-right: 30px;'}
  @media (min-width: 680px) {
    margin-left: 0px;
    margin-right: 0px;
    padding-bottom: 20px;
    border-bottom: 4px solid #efe0d7;
  }
`

const FirstLine = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
  @media (min-width: 680px) {
    height: 80px;
    margin-bottom: 0px;
  }
`

const Name = styled.p`
  font-family: Dignitas, sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.54px;
  margin-right: 8px;
  @media (min-width: 680px) {
    font-size: 28px;
    line-height: 32px;
    letter-spacing: 0.75px;
  }
`

const Level = styled.p`
  font-size: 10px;
  line-height: 34px;
  letter-spacing: 0.24px;
  @media (min-width: 680px) {
    font-family: Dignitas, sans-serif;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: 0.75px;
  }
`

const University = styled.p`
  font-weight: 700;
  font-size: 11px;
  line-height: 17px;
  letter-spacing: 0.42px;
  @media (min-width: 680px) {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.54px;
  }
`

const Subject = styled.p`
  font-size: 11px;
  line-height: 17px;
  letter-spacing: 0.42px;
  @media (min-width: 680px) {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.54px;
  }
`

const SwipeContainer = styled.div`
  display: flex;
  transform: rotate(90deg);
  position: absolute;
  z-index: 10;
  right: -34px;
  top: 173px;
  @media (min-width: 1200px) {
    display: none;
  }
`

const SwipeText = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: #0859ff;
`

const Arrow = styled.img`
  width: 12px;
  margin-left: 16px;
  transform: rotate(-90deg);
  transition: 0.14s ease-in-out;
  ${p => p.open && 'transform: rotate(90deg);'}
`

const Desktop = styled.div`
  display: none;
  @media (min-width: 1200px) {
    display: block;
  }
`
const Mobile = styled.div`
  @media (min-width: 1200px) {
    display: none;
  }
`

const Person = ({ name, level, university, subject, portrait, biography, smallOnTablet }) => {
  const fluid = get(portrait, 'childImageSharp.fluid')
  const [currentSlide, setCurrentSlide] = useState(1)
  return (
    <>
      <Desktop>
        <Wrapper>
          <TestimonialImgContainer>{fluid && <TestimonialImg fluid={fluid} alt={name} />}</TestimonialImgContainer>
          <ButtonContainer>
            <Button src={button} alt="" />
          </ButtonContainer>
          <Description>
            <FirstLine>
              {name && <Name>{name}</Name>}
              {level && <Level>{level}</Level>}
            </FirstLine>
            {university && <University>{university}</University>}
            {subject && <Subject>{subject}</Subject>}
          </Description>
          {biography && <Bio>{RichText.render(biography)}</Bio>}
        </Wrapper>
      </Desktop>
      <Mobile>
        {biography ? (
          <Wrapper>
            <Carousel startIndex={1} setCurrentSlide={setCurrentSlide}>
              <Bio>{RichText.render(biography)}</Bio>
              <div>
                <TestimonialImgContainer smallOnTablet={smallOnTablet}>
                  {fluid && <TestimonialImg smallOnTablet={smallOnTablet} fluid={fluid} alt={name} />}
                </TestimonialImgContainer>
                <ButtonContainer>
                  <Button smallOnTablet={smallOnTablet} src={button} alt="" />
                </ButtonContainer>
                <Description carousel={true}>
                  <FirstLine>
                    {name && <Name>{name}</Name>}
                    {level && <Level smallOnTablet={smallOnTablet}>{level}</Level>}
                  </FirstLine>
                  {university && <University>{university}</University>}
                  {subject && <Subject>{subject}</Subject>}
                </Description>
              </div>
            </Carousel>
            <SwipeContainer>
              <SwipeText>{currentSlide === 1 ? 'open' : 'close'} bio</SwipeText>
              <Arrow open={currentSlide === 0} src={arrow} alt="" />
            </SwipeContainer>
          </Wrapper>
        ) : (
          <Wrapper>
            <TestimonialImgContainer smallOnTablet={smallOnTablet}>
              {fluid && <TestimonialImg smallOnTablet={smallOnTablet} fluid={fluid} alt={name} />}
            </TestimonialImgContainer>
            <ButtonContainer>
              <Button smallOnTablet={smallOnTablet} src={button} alt="" />
            </ButtonContainer>
            <Description>
              <FirstLine>
                {name && <Name>{name}</Name>}
                {level && <Level smallOnTablet={smallOnTablet}>{level}</Level>}
              </FirstLine>
              {university && <University>{university}</University>}
              {subject && <Subject>{subject}</Subject>}
            </Description>
          </Wrapper>
        )}
      </Mobile>
    </>
  )
}

export default Person
