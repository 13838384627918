import React from 'react'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'
import Carousel from './Carousel/Carousel'
import Person from './Person'

const TestimonialsOuterContainer = styled.div`
  position: relative;
  @media (min-width: 1200px) {
    top: -200px;
  }
`

const TestimonialsHeadingContainer = styled.div`
  position: relative;
  transform: translateX(-18px);
  @media (min-width: 680px) {
    transform: translateX(0px);
  }
`
const TestimonialsHeading = styled.h1`
  font-family: Dignitas, sans-serif;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.76px;
  color: #efe0d7;
  margin-left: 18px;
  @media (min-width: 680px) {
    margin-left: 28px;
    font-size: 37px;
    line-height: 40px;
    letter-spacing: 1.28px;
  }
  @media (min-width: 1200px) {
    font-size: 60px;
    line-height: 72px;
    letter-spacing: 2.08px;
  }
`

const VerticalLine = styled.div`
  width: 3px;
  height: 158px;
  background-color: #efe0d7;
  position: absolute;
  left: 0px;
  top: 4px;
  @media (min-width: 680px) {
    width: 4px;
    height: 238px;
    top: 7px;
  }
  @media (min-width: 1200px) {
    height: 375px;
    top: 15px;
  }
`

const TestimonialsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 55px;
  @media (min-width: 1200px) {
    margin-bottom: 114px;
  }
  .embla__slide__inner {
    height: 100%;
  }
`

const Testimonial = styled.div`
  height: 100%;
  flex: 0 0 100%;
  display: flex;
  justify-content: center;
  @media (min-width: 1200px) {
    display: block;
  }
`

const TestimonialInner = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 1200px) {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
  }
`

const Left = styled.div`
  max-width: 190px;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 680px) {
    max-width: 273px;
  }
  @media (min-width: 1200px) {
    width: 100%;
    max-width: 384px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const LeftInnerContainer = styled.div`
  @media (min-width: 1200px) {
    max-width: 273px;
  }
`

const Right = styled.div`
  max-width: 350px;
  margin-top: auto;
  margin-bottom: auto;
  @media (min-width: 680px) {
    max-width: 415px;
  }
  @media (min-width: 1200px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: initial;
    margin-top: 174px;
  }
`

const Quote = styled.blockquote`
  margin-top: 32px;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  @media (min-width: 680px) {
    margin-top: 76px;
    font-size: 17px;
    line-height: 30px;
    letter-spacing: 0.3px;
  }
  @media (min-width: 1200px) {
    margin-top: 0px;
    font-size: 22px;
    line-height: 35px;
    margin-bottom: 136px;
  }
`

const testimonials = [
  {
      "imageKey": "daniel",
      "name": "Daniel E",
      "level": null,
      "university": "University of Cambridge",
      "subject": "English Language and Literature",
      "quote": "“The platform is intuitive and easy to use. Being able to set my own commitment level means I can fit rewarding, online teaching around my current studies and busy schedules.”"
  },
  {
      "imageKey": "rowan",
      "name": "Rowan W",
      "level": null,
      "university": "University of Cambridge",
      "subject": "Mathematics",
      "quote": "“I feel like there’s always a team behind me. Being able to contact the student’s education consultant is also a great feature: it gives me an added level of support and makes my sessions even more academically rewarding and precise.”"
  },
  {
      "imageKey": "harry",
      "name": "Harry M",
      "level": null,
      "university": "University of Oxford",
      "subject": "Philosophy",
      "quote": "“I love it when you’re going over a topic with students and they really start to get it; they start asking questions and become genuinely excited by what they’ve learned.”"
  }
];


const q = graphql`
  query testimonial {
    harry: file(relativePath: { eq: "harry.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    daniel: file(relativePath: { eq: "daniel.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    rowan: file(relativePath: { eq: "rowan.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
const Testimonials = () => {
  return (
    <StaticQuery
      query={q}
      render={data => {
        return (
          <TestimonialsOuterContainer>
            <TestimonialsHeadingContainer>
              <TestimonialsHeading>{`testimonials`}</TestimonialsHeading>
              <VerticalLine />
            </TestimonialsHeadingContainer>
            <TestimonialsContainer>
              {testimonials.length > 0 && (
                <Carousel autoplay delayLength={5000} showDots>
                  {testimonials.map((testimonial, index) => {
                    const { imageKey, name, level, university, subject, quote } = testimonial
                    return (
                      <Testimonial key={index}>
                        <TestimonialInner>
                          <Left>
                            <LeftInnerContainer>
                              <Person
                                name={name}
                                level={level}
                                university={university}
                                subject={subject}
                                portrait={data[imageKey]}
                              />
                            </LeftInnerContainer>
                          </Left>
                          <Right>{quote && <Quote>{quote}</Quote>}</Right>
                        </TestimonialInner>
                      </Testimonial>
                    )
                  })}
                </Carousel>
              )}
            </TestimonialsContainer>
          </TestimonialsOuterContainer>
        )}
      } 
    />
  );
}

export default Testimonials
