import React, { useEffect, useLayoutEffect } from 'react'
import styled from 'styled-components'
import { Link, graphql } from 'gatsby'
import { Parallax } from 'react-scroll-parallax'
import { useSpring, animated } from 'react-spring'
import Img from 'gatsby-image'

import Container from '../styles/Container'
import Testimonials from '../components/Testimonials'
import SEO from '../components/SEO'

import { useScroll } from '../hooks/useScroll'
import useWindowSize from '../hooks/useWindowSize'

import swoop from '../images/swoop.png'
import arrowUp from '../images/arrow-up.svg'
import grid75x76 from '../images/grid-75x76.svg'
import grid75x76Top from '../images/grid-75x76-top.svg'
import gridSmall from '../images/grid-small.svg'
import gridMid from '../images/grid-mid.svg'
import grid36x38 from '../images/grid-36x38.svg'

import gridHomepage1 from '../images/grid-homepage-1.svg'
import gridHomepage1Wide from '../images/grid-homepage-1-wide.svg'
import gridHomepage2 from '../images/grid-homepage-2.svg'
import gridHomepage2Wide from '../images/grid-homepage-2-wide.svg'

const Wrapper = styled.div``

const CustomContainer = styled(Container)`
  padding-left: 46px;
  padding-right: 46px;
`

const SemiCircleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 44px;
  margin-bottom: 32px;
  @media (min-width: 680px) {
    justify-content: flex-start;
    margin-top: 54px;
    margin-bottom: 20px;
  }
  @media (min-width: 1200px) {
    margin-top: 70px;
    margin-bottom: 100px;
  }
`

const SemiCircle = styled(Img)`
  width: 65px;
  margin-right: 18px;
  @media (min-width: 680px) {
    width: 100px;
    position: relative;
    left: -30px;
  }
  @media (min-width: 1200px) {
    width: 150px;
    left: -55px;
  }
`

const MainHeading = styled.h1`
  max-width: 226px;
  margin-bottom: 25px;
  font-family: Dignitas, sans-serif;
  font-size: 44px;
  line-height: 47px;
  font-weight: 700;
  letter-spacing: 0.25px;
  color: #0859ff;
  @media (min-width: 680px) {
    max-width: 390px;
    font-size: 67px;
    line-height: 73px;
    letter-spacing: 1.4px;
  }
  @media (min-width: 980px) {
    max-width: 680px;
    font-size: 86px;
    line-height: 96px;
    letter-spacing: 1.8px;
  }
  @media (min-width: 1200px) {
    max-width: 930px;
    margin-bottom: 60px;
    font-size: 110px;
    line-height: 120px;
    letter-spacing: 2.35px;
  }
  @media (min-width: 1300px) {
    max-width: 930px;
    margin-bottom: 60px;
    font-size: 120px;
    line-height: 130px;
    letter-spacing: 2.5px;
  }
`

const Elevated = styled.span`
  display: none;
  @media (min-width: 1200px) {
    display: inline;
    position: relative;
    bottom: 0px;
    font-family: MarkOT, sans-serif;
    font-weight: 300;
    font-size: 22px;
    line-height: 21px;
    letter-spacing: 0.52px;
  }
`

const MainSubHeading = styled.h2`
  max-width: 160px;
  font-weight: 300;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.3px;
  color: #0859ff;
  @media (min-width: 680px) {
    max-width: 190px;
  }
  @media (min-width: 1200px) {
    max-width: 350px;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: 0.52px;
  }
`

const TapeMeasureContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  @media (min-width: 680px) {
    overflow: visible;
    position: relative;
  }
  @media (min-width: 1200px) {
    height: 0px;
  }
`

const TapeMeasure = styled(Img)`
  width: 278px;
  position: relative;
  top: 0px;
  right: -57px;
  @media (min-width: 680px) {
    width: 392px;
    top: -40px;
  }
  @media (min-width: 1200px) {
    width: 638px;
    position: absolute;
    top: 30px;
    right: -144px;
  }
`

const SecondaryHeading = styled.h1`
  max-width: 184px;
  margin-bottom: 42px;
  padding-top: 10px;
  border-top: 3px solid #efe0d7;
  font-family: Dignitas, sans-serif;
  font-size: 28px;
  line-height: 30px;
  color: #efe0d7;
  @media (min-width: 680px) {
    max-width: 320px;
    font-size: 37px;
    line-height: 40px;
    letter-spacing: 0.46px;
  }
  @media (min-width: 1200px) {
    max-width: 480px;
    margin-top: 200px;
    padding-top: 14px;
    border-top: 4px solid #efe0d7;
    font-size: 60px;
    line-height: 62px;
    letter-spacing: 0.75px;
  }
`

const SecondarySubHeading = styled.h2`
  max-width: 330px;
  margin-bottom: 24px;
  font-weight: 100;
  font-size: 19px;
  line-height: 24px;
  @media (min-width: 680px) {
    max-width: 310px;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.09px;
  }
  @media (min-width: 1200px) {
    max-width: 460px;
    font-size: 30px;
    line-height: 44px;
    letter-spacing: 0.14px;
  }
`

const ParagraphContainer = styled.div`
  max-width: 330px;
  @media (min-width: 1200px) {
    margin-right: 24px;
  }
`

const Paragraph = styled.p`
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 14px;
  @media (min-width: 680px) {
    max-width: 340px;
    letter-spacing: 0.25px;
  }
  @media (min-width: 1200px) {
    max-width: 414px;
  }
  @media (min-width: 1300px) {
    min-width: 414px;
  }
`

const Mid = styled.div`
  @media (min-width: 1200px) {
    display: flex;
  }
`

const UniversitiesList = styled.ul`
  max-width: 180px;
  border-top: 3px solid #efe0d7;
  border-bottom: 3px solid #efe0d7;
  margin-top: 42px;
  margin-bottom: 60px;
  @media (min-width: 680px) {
    max-width: 420px;
    margin-left: auto;
    transform: translateX(140px);
    background-color: #fdfbf9;
    border-top: 4px solid #efe0d7;
    border-bottom: 4px solid #efe0d7;
    margin-bottom: 110px;
    margin-top: 60px;
  }
  @media (min-width: 740px) {
    transform: translateX(160px);
  }
  @media (min-width: 1200px) {
    transform: translateX(0px);
    width: 100%;
    max-width: 300px;
    margin-left: auto;
    margin-bottom: 60px;
    position: relative;
    top: -10px;
    margin-top: 0px;
  }
  @media (min-width: 1400px) {
    max-width: 415px;
  }
`

const UniversitiesListItem = styled.li`
  font-size: 10px;
  line-height: 30px;
  letter-spacing: 0.17px;
  :not(:last-child) {
    border-bottom: 1px solid #efe0d7;
  }
  @media (min-width: 680px) {
    font-size: 14px;
    letter-spacing: 0.25px;
    line-height: 36px;
  }
`

const SwoopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const Swoop = styled.img`
  width: 210px;
  margin-top: 70px;
  position: relative;
  right: -30px;
  transform: scaleX(-1);
  @media (min-width: 680px) {
    right: 30px;
  }
  @media (min-width: 1200px) {
    margin-top: 120px;
    width: 315px;
    transform: scaleX(1);
    right: 84px;
  }
`

const NextPageContainer = styled.div`
  margin-top: 46px;
  margin-bottom: 84px;
  text-align: center;
  @media (min-width: 1200px) {
    margin-top: 134px;
    margin-bottom: 24px;
  }
`

const NextPage = styled(Link)`
  position: relative;
  font-family: Dignitas, sans-serif;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  letter-spacing: 0.86px;
  color: #0859ff;
  @media (min-width: 1200px) {
    font-size: 110px;
    line-height: 92px;
    letter-spacing: 2.36px;
    padding-top: 21px;
    right: 9px;
    padding-right: 48px;
    padding-left: 48px;
    padding-bottom: 21px;
    background-color: #fdfbf9;
    :after {
      transition: 0.1s ease-in-out;
      content: '';
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
      width: 0px;
      height: 5px;
      background-color: #0859ff;
    }
    :hover {
      :after {
        width: 108px;
      }
    }
  }
  @media (min-width: 1700px) {
    right: 17px;
    padding-left: 48px;
  }
`

const ReturnToTopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 40px;
  padding-top: 24px;
  @media (min-width: 1200px) {
    padding-bottom: 153px;
    padding-top: 0px;
  }
`

const ReturnToTopButton = styled.button`
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.11px;
  color: #0859ff;
  position: relative;
  @media (min-width: 1200px) {
    transform: translateX(190px);
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.13px;
    padding-top: 28px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 29px;
    background-color: #fdfbf9;
    margin-top: 6px;
    :after {
      transition: 0.1s ease-in-out;
      content: '';
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
      width: 0px;
      height: 1px;
      background-color: #0859ff;
    }
    :hover {
      :after {
        width: 30px;
      }
    }
  }
`

const ReturnToTopImg = styled.img`
  display: block;
  height: 33px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 8px;
  @media (min-width: 1200px) {
    display: none;
  }
`

const Bottom = styled.div``

const OuterContainer = styled.div`
  position: relative;
  @media (min-width: 1200px) {
    margin-top: -260px;
  }
`

const GridLeft = styled.div`
  position: absolute;
  width: 100%;
  height: 375px;
  background-image: url(${gridSmall});
  background-position: calc(50% + 15px) top;
  z-index: -1;
  @media (min-width: 1200px) {
    background-position: right top;
    left: 0px;
    width: 80%;
    height: 215px;
    background-image: url(${grid75x76});
  }
`

const GridSmallTop = styled.div`
  display: none;
  display: block;
  position: absolute;
  width: 100%;
  height: 5px;
  z-index: -1;
  background-color: #fdfbf9;
  @media (min-width: 1200px) {
    display: none;
  }
`

const GridLeftSecondPart = styled.div`
  display: none;
  @media (min-width: 1200px) {
    display: block;
    position: absolute;
    z-index: -1;
    background-position: right top;
    top: 215px;
    left: 0px;
    width: 80%;
    height: 152px;
    background-image: url(${gridHomepage1});
  }
  @media (min-width: 1700px) {
    background-image: url(${gridHomepage1Wide});
  }
`

const GridLeftThirdPart = styled.div`
  display: none;
  @media (min-width: 1200px) {
    display: block;
    position: absolute;
    z-index: -1;
    background-position: right top;
    top: 367px;
    left: 0px;
    width: 80%;
    height: 152px;
    background-image: url(${grid75x76Top});
  }
`

const GridLeftFourthPart = styled.div`
  display: none;
  @media (min-width: 1200px) {
    display: block;
    position: absolute;
    z-index: -1;
    background-position: right top;
    top: 519px;
    left: 0px;
    width: 80%;
    height: 152px;
    background-image: url(${gridHomepage2});
  }
  @media (min-width: 1700px) {
    background-image: url(${gridHomepage2Wide});
  }
`

const GridLeftFifthPart = styled.div`
  display: none;
  @media (min-width: 1200px) {
    display: block;
    position: absolute;
    z-index: -1;
    background-position: right top;
    top: 671px;
    left: 0px;
    width: 80%;
    height: 152px;
    background-image: url(${gridHomepage1});
  }
  @media (min-width: 1700px) {
    background-image: url(${gridHomepage1Wide});
  }
`

const GridRight = styled.div`
  position: absolute;
  width: 100%;
  height: 151px;
  bottom: 0px;
  background-image: url(${gridMid});
  background-position: calc(50% + 2px) top;
  z-index: -1;
  @media (min-width: 1200px) {
    right: 0px;
    width: 20%;
    height: 100%;
    background-image: url(${grid36x38});
    background-position: initial;
    z-index: -1;
  }
`

const O = styled(animated.span)`
  display: inline-block;
  @media (max-width: 679px) {
    transform: translateY(0px) !important;
  }
`

const Span = styled.span`
  display: inline-block;
`

const list_of_universities = [
  {"university":"University of Oxford"},
  {"university":"University of Cambridge"},
  {"university":"London School of Economics"},
  {"university":"Imperial College London"},
  {"university":"Harvard University"},
  {"university":"Yale University"},
  {"university":"Stanford University"},
  {"university":"Princeton University"},
  {"university":"Brown University"},
  {"university":"Massachusetts Institute of Technology"},
  {"university":"University of California, Los Angeles"},
  {"university":"Columbia University"},
  {"university":"Cornell University"},
  {"university":"Dartmouth College"},
  {"university":"University of Pennsylvania"},
  {"university":"California Institute of Technology"},
  {"university":"etc… apply to see if you’re eligible!"}
];
const Index = ({ data }) => {
  const semiCircleFluid = data.semiCircleImg.childImageSharp.fluid
  const tapeMeasureFluid = data.tapeMeasureImg.childImageSharp.fluid

  const goToTop = () => {
    if (typeof window !== `undefined`) {
      window.scrollTo({ behavior: 'smooth', top: 0 })
    }
  }

  const { scrollY, setScrollY } = useScroll()
  const [scrollAnimation, set] = useSpring(() => ({ y: '0' }))
  useLayoutEffect(() => {
    // To reset scroll position after page change
    setTimeout(() => setScrollY(0), 50)
  }, [setScrollY])
  useEffect(() => {
    set({ y: `${scrollY}` })
  }, [scrollY, set])

  const { width } = useWindowSize()

  return (
    <>
      <CustomContainer>
        <SEO title={``} description={``} images={``} />
        <Wrapper>
          <SemiCircleContainer>
            <SemiCircle fluid={semiCircleFluid} alt="" />
          </SemiCircleContainer>
          <MainHeading>
            <Span>
              C<O style={{ transform: scrollAnimation.y.interpolate(y => `translateY(-${y / 3}px)`) }}>o</O>
              nnecting
            </Span>{' '}
            <Span>
              excepti
              <O
                style={{
                  transform: scrollAnimation.y.interpolate(y => `translateY(-${Math.max((y - 100) / 3, 0)}px)`),
                }}
              >
                o
              </O>
              nal
            </Span>{' '}
            <Span>
              tut
              <O
                style={{
                  transform: scrollAnimation.y.interpolate(y => `translateY(-${Math.max((y - 100) / 3, 0)}px)`),
                }}
              >
                o
              </O>
              rs
            </Span>{' '}
            <Span>
              t
              <O
                style={{
                  transform: scrollAnimation.y.interpolate(y => `translateY(-${Math.max((y - 200) / 3, 0)}px)`),
                }}
              >
                o
              </O>
            </Span>{' '}
            the{' '}
            <Span>
              w
              <O
                style={{
                  transform: scrollAnimation.y.interpolate(y => `translateY(-${Math.max((y - 200) / 3, 0)}px)`),
                }}
              >
                o
              </O>
              rld&apos;s
            </Span>{' '}
            <Span>
              m
              <O
                style={{
                  transform: scrollAnimation.y.interpolate(y => `translateY(-${Math.max((y - 300) / 3, 0)}px)`),
                }}
              >
                o
              </O>
              st
            </Span>{' '}
            <Span>
              ambiti
              <O
                style={{
                  transform: scrollAnimation.y.interpolate(y => `translateY(-${Math.max((y - 300) / 3, 0)}px)`),
                }}
              >
                o
              </O>
              us
            </Span>{' '}
            students{' '}
            <Parallax styleOuter={{ display: 'inline-block' }} y={['6px', '-70px']}>
              <Elevated>elevating knowledge</Elevated>
            </Parallax>
          </MainHeading>
          <MainSubHeading>{`Rewarding work for tuition in A-Level, GCSE, IB, AP, SAT 1 and SAT2, and other subjects.`}</MainSubHeading>
          <TapeMeasureContainer>
            <Parallax disabled={width < 680} y={width < 1200 ? ['50px', '-40px'] : ['0px', '-120px']}>
              <TapeMeasure fluid={tapeMeasureFluid} alt="" />
            </Parallax>
          </TapeMeasureContainer>
          <SecondaryHeading>{`Oros education is a tutoring platform with high standards for its high achievers`}</SecondaryHeading>
          <SecondarySubHeading>{`Oros Education was founded with the aim of providing our Oxbridge and Ivy League trained tutors with a good source of income which fits around them.`}</SecondarySubHeading>
          <Mid>
            
              <ParagraphContainer>
                 <Paragraph>{`Oros tutors are one-of-a-kind. Hard-working, responsible, passionate and keen - our tutors are undergraduates, postgraduates, doctorate students and professional teachers who are looking for stable but flexible tutoring work which fits around their studies, work and other commitments. Could this be you?`}</Paragraph>
                 <Paragraph>{`We accept applications from tutors currently reading at, or already graduated from, the following universities:`}</Paragraph>
              </ParagraphContainer>
            
            {list_of_universities && (
              <UniversitiesList>
                {list_of_universities.map(({ university }, index) => {
                  return <UniversitiesListItem key={index}>{university}</UniversitiesListItem>
                })}
              </UniversitiesList>
            )}
          </Mid>
          <Testimonials />
        </Wrapper>
      </CustomContainer>
      <OuterContainer>
        <GridLeft />
        <GridSmallTop />
        <GridLeftSecondPart />
        <GridLeftThirdPart />
        <GridLeftFourthPart />
        <GridLeftFifthPart />
        <GridRight />
        <Container>
          <Bottom>
            <SwoopContainer>
              <Swoop src={swoop} alt="" />
            </SwoopContainer>
            <NextPageContainer>
              <NextPage to="/teach-with-us">tell me more</NextPage>
            </NextPageContainer>
            <ReturnToTopContainer>
              <ReturnToTopButton onClick={goToTop}>
                <ReturnToTopImg src={arrowUp} alt="Return to Top" />
                <div>return to top</div>
              </ReturnToTopButton>
            </ReturnToTopContainer>
          </Bottom>
        </Container>
      </OuterContainer>
    </>
  )
}

export default Index

export const query = graphql`
  query HomepageQuery {
    semiCircleImg: file(relativePath: { eq: "semi-circle.png" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    tapeMeasureImg: file(relativePath: { eq: "tape-measure.png" }) {
      childImageSharp {
        fluid(maxWidth: 638, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
