import React, { useState, useEffect, useCallback } from 'react'
import EmblaCarouselReact from 'embla-carousel-react'
import useInterval from '../../hooks/useInterval'
import './embla.css'

const Carousel = ({ autoplay, delayLength, children, showDots, startIndex, setCurrentSlide }) => {
  const [embla, setEmbla] = useState(null)
  const [, setPrevBtnEnabled] = useState(false)
  const [, setNextBtnEnabled] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState([])
  const [delay] = useState(delayLength)
  const [isRunning] = useState(autoplay)
  const [cancelInterval, setCancelInterval] = useState(false)

  const scrollTo = useCallback(index => embla.scrollTo(index), [embla])
  const scrollNext = useCallback(() => embla.scrollNext(), [embla])

  useInterval(
    () => {
      if (cancelInterval) {
        setCancelInterval(false)
        return
      }
      if (selectedIndex === scrollSnaps.length - 1) {
        scrollTo(0)
      } else {
        scrollNext()
      }
    },
    isRunning ? delay : null
  )

  useEffect(() => {
    const onSelect = () => {
      setSelectedIndex(embla.selectedScrollSnap())
      setPrevBtnEnabled(embla.canScrollPrev())
      setNextBtnEnabled(embla.canScrollNext())
    }
    if (embla) {
      setScrollSnaps(embla.scrollSnapList())
      embla.on('select', onSelect)
      onSelect()
      if (setCurrentSlide) {
        embla.on('select', () => setCurrentSlide(embla.selectedScrollSnap()))
      }
    }
    return () => embla && embla.destroy()
  }, [embla, setCurrentSlide])

  return (
    <div className="embla__outer">
      {/* eslint-disable-next-line */}
      <div
        className="embla"
        onClick={() => {
          setCancelInterval(true)
        }}
        onTouchStart={() => {
          setCancelInterval(true)
        }}
      >
        <EmblaCarouselReact
          className="embla__viewport"
          emblaRef={setEmbla}
          options={{ loop: false, startIndex: startIndex || 0 }}
          htmlTagName="div"
        >
          <div className="embla__container">
            {children.map((Child, index) => (
              <div className="embla__slide" key={index}>
                <div className="embla__slide__inner">{Child}</div>
              </div>
            ))}
          </div>
        </EmblaCarouselReact>
        {showDots && (
          <div className="embla__dots">
            {scrollSnaps.map((snap, index) => (
              <DotButton selected={index === selectedIndex} onClick={() => scrollTo(index)} key={index} index={index} />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default Carousel

const DotButton = ({ selected, onClick, index }) => (
  <button className={`embla__dot${selected ? ' is-selected' : ''}`} onClick={onClick} aria-label={index + 1} />
)
